import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Typography } from "./";

const DialogHeader = ({ children, onClose }) => {
  return (
    <Box px={2} display="flex" justifyContent="space-between">
      <Typography variant="h6">{children}</Typography>
      <Box textAlign="right">
        {onClose && (
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default function DialogExtended({
  label = "untitled-dialog",
  open,
  heading,
  handleClose,
  children,
  maxWidth = "md",
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={label}
      open={open}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogHeader id="customized-dialog-title" onClose={handleClose}>
        {heading}
      </DialogHeader>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
