import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  List,
  IconButton,
  ListItemIcon,
  ListItemText,
  Drawer,
  ListItem,
} from "@material-ui/core/";
import { Menu, ChevronLeft } from "@material-ui/icons";
import { Divider, Typography, Avatar, Wrapper } from "components";
import { menu } from "../config";
import { useAuth } from "hooks";
import styled, { css } from "styled-components";
import { mediaQuery } from "styles";

const AppDrawer = styled(({ drawerOpenWidth, drawerCloseWidth, ...rest }) => (
  <Drawer {...rest} />
))`
  && {
    flex-shrink: 0;
    white-space: nowrap;

    ${props =>
      props.open
        ? css`
            transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            width: ${props => `${props.drawerOpenWidth}px`};
          `
        : css`
            overflow-x: hidden;
            transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            width: ${props => `${props.drawerCloseWidth - 16}px`};
          `}

    .MuiDrawer-paperAnchorDockedLeft {
      background-color: ${({ theme }) => theme.palette.primary.main};
      overflow-x: hidden;

      ${props =>
        props.open
          ? css`
              transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
              width: ${props => `${props.drawerOpenWidth}px`};
            `
          : css`
              transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
              width: ${props => `${props.drawerCloseWidth - 16}px`};
            `}
    }

    ${mediaQuery.md`
    ${props =>
      !props.open &&
      css`
        width: ${props => `${props.drawerCloseWidth}px`};
      `}
    .MuiDrawer-paperAnchorDockedLeft {
      ${props =>
        !props.open &&
        css`
          width: ${props => `${props.drawerCloseWidth}px`};
        `}
    }
    `}
  }
`;

const DrawerToolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 8px;
  min-height: 56px;

  ${mediaQuery.md`
    min-height: 64px;
  `}
`;

const LinkItem = styled(ListItem)`
  span {
    color: ${props => props.theme.palette.grey[100]};
  }

  svg {
    fill: ${props => props.theme.palette.grey[600]};
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }
`;

const AdminDrawer = ({
  open,
  drawerOpenWidth,
  drawerCloseWidth,
  handleToggleChange,
}) => {
  const { user } = useAuth();

  return (
    <AppDrawer
      drawerOpenWidth={drawerOpenWidth}
      drawerCloseWidth={drawerCloseWidth}
      variant="permanent"
      open={open}
    >
      <DrawerToolbar>
        {open ? (
          <>
            <Typography color="secondary" variant="h6"></Typography>
            {/* <IconButton onClick={handleToggleChange}>
              <ChevronLeft color="secondary" />
            </IconButton> */}
          </>
        ) : (
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleToggleChange}
            edge="start"
          >
            <Menu />
          </IconButton>
        )}
      </DrawerToolbar>
      <Wrapper
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        mb={2}
      >
        <Wrapper position="relative">
          <Avatar src={user.profilePic} name={user.name} size="40" />
          <Wrapper
            position="absolute"
            borderRadius="50%"
            bgcolor="#00C11F"
            width="10px"
            height="10px"
            ml={1}
            bottom={0}
            left={18}
            boxShadow="0px 2px 2px rgba(0, 0, 0, 0.15)"
          />
        </Wrapper>
        <Wrapper>
          <Typography fontSize="16px" variant="body2" ml={1} fontColor="white">
            {user.name}
          </Typography>
          <Wrapper display="flex" alignItems="center">
            <Typography
              variant="body1"
              ml={1}
              fontColor="white"
              fontSize="14px"
            >
              Online
            </Typography>
          </Wrapper>
        </Wrapper>
      </Wrapper>
      <List>
        {menu.map((menu, index) => (
          <Fragment key={index}>
            <Link to={menu.link}>
              <LinkItem button>
                <ListItemIcon>{menu.icon}</ListItemIcon>
                <ListItemText primary={menu.label} />
              </LinkItem>
            </Link>
          </Fragment>
        ))}
      </List>
    </AppDrawer>
  );
};

export default AdminDrawer;
