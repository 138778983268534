import React from "react";
import styled from "styled-components";
import { TextField, Button } from "../components";
import { Box } from "@material-ui/core";

const SearchField = styled(TextField)`
  background: #fff;
  border-radius: 35px;

  .MuiOutlinedInput-root {
    border-radius: 35px;
  }
`;

const SearchButton = styled(Button)`
  position: absolute;
  border-radius: 25px;
  right: 0px;
  top: 4px;
  padding: 6px 30px;
`;

export default function Search({ inputRef, onClick }) {
  return (
    <Box position="relative">
      <SearchField
        placeholder="Search..."
        fullWidth
        variant="outlined"
        margin="dense"
        inputRef={inputRef}
        name="search"
      />
      <SearchButton variant="outlined" color="primary" ml={2} onClick={onClick}>
        Search
      </SearchButton>
    </Box>
  );
}
