import React from "react";
import Routes from "routes/routes";
import Theme from "styles";
import { AuthProvider } from "./hooks/useAuth";
import { FilterProvider } from "./hooks/useFilter";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider, QueryClient } from "react-query";
import { AuthService } from "./utils";

// Create a client
const queryClient = new QueryClient();
const Auth = new AuthService();

export default function App() {
  const initialState = Auth.getAuth();
  // const initialState = {
  //   user: {
  //     id: 407593330632,
  //     email: "updates@orocube.net",
  //     name: "ORO TV",
  //     organizationId: 361302191652,
  //     profilePic:
  //       "https://orocube.zendesk.com/system/photos/360818460152/profile_image_407593330632_2028803.png",
  //     accessToken:
  //       "93c2b62af8e9d627627c4521801d0907f24cff5581d2d6ea291a49aeec13642f",
  //     isAdmin: true,
  //   },
  //   token:
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NDA3NTkzMzMwNjMyLCJpYXQiOjE2NDk0OTQ2NzIsImV4cCI6MTY0OTU4MTA3Mn0.7yWgsQqpVFlumRn89WktokNQ7wOSPQcCuPa_EAX47NU",
  // };

  return (
    <QueryClientProvider client={queryClient}>
      <Theme>
        <AuthProvider initialState={initialState}>
          <FilterProvider>
            <Routes />
          </FilterProvider>
        </AuthProvider>
      </Theme>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}
