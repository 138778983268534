import React, { useRef } from "react";
import { Button, RichEditor, Wrapper, Avatar } from "components";
import styled from "styled-components";
import { useUpdateTicket } from "hooks";

const ReplyWrapper = styled.div`
  background-color: #fff;
  margin: 24px 0;
  display: flex;
  gap: 16px;
  width: 100%;
`;

export default function Reply({ userId, ticketId }) {
  const editorRef = useRef();
  const { mutate, isLoading: isUpdating } = useUpdateTicket();

  const handleAddComment = async data => {
    const body = {
      comment: {
        html_body: data,
        author_id: userId,
        public: true,
      },
    };

    mutate(
      { ticketId, data: body },
      {
        onSuccess: () => {
          editorRef.current.resetEditor();
        },
      }
    );
  };

  return (
    <>
      <ReplyWrapper>
        <Avatar size={50} />
        <RichEditor
          placeholder="Leave your reply here..."
          ref={editorRef}
          handleAddComment={handleAddComment}
        />
      </ReplyWrapper>
      <Wrapper display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          variant="contained"
          disabled={isUpdating}
          loading={isUpdating}
          onClick={() => editorRef.current.handleSave()}
        >
          Reply
        </Button>
      </Wrapper>
    </>
  );
}
