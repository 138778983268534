import React, { useEffect, useState, useCallback } from "react";
import { Typography, Select, Button } from "components";
import styled from "styled-components";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-light.css";
import { format, formatDistance } from "date-fns";
import { ArrowForward } from "@material-ui/icons";
import { IconButton, Grid, Box, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  statusOptions,
  priorityOptions,
  statusColors,
  priorityColors,
  statusInitials,
  userStatusOptions,
} from "../config";
import { useTickets, useFilter, useAuth } from "hooks";
import { useForm, Controller } from "react-hook-form";
import { Search } from "components";
import { useParams } from "react-router-dom";

const Heading = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const MetaBg = styled.p`
  span {
    background: ${({ type, color }) =>
      type === "priority" ? "transparent" : color};
    border: 1px solid;
    border-color: ${({ color }) => color};
    padding: 6px 14px 4px;
    border-radius: 16px;
    color: ${({ type, color }) => (type === "priority" ? color : "#fff")};
    text-transform: capitalize;
  }
`;

const columns = [
  { name: "id", header: "ID", defaultWidth: 150 },
  {
    name: "status",
    header: "Status",
    defaultWidth: 150,
    render: ({ value }) => (
      <MetaBg type="status" color={statusColors[value]}>
        {value ? <span>{statusInitials[value]}</span> : ""}
      </MetaBg>
    ),
  },
  {
    name: "subject",
    header: "Subject",
    defaultFlex: 3,
    render: ({ data }) => (
      <Box>
        <Typography>{data.subject}</Typography>
        <Typography variant="subtitle1">
          {format(new Date(data.created_at), "yyyy/MM/dd")}
        </Typography>
      </Box>
    ),
  },
  {
    name: "updated_at",
    header: "Last Updated",
    defaultFlex: 1,
    render: ({ value }) => (
      <>
        {formatDistance(new Date(), new Date(value), {
          includeSeconds: true,
        })}{" "}
        ago
      </>
    ),
  },
  {
    defaultWidth: 80,
    render: ({ data }) => (
      <IconButton component={Link} to={`/users/tickets/${data.id}`}>
        <ArrowForward />
      </IconButton>
    ),
  },
];

export default function UserTickets() {
  const { id } = useParams();
  const { user } = useAuth();
  const { register, control, watch, getValues } = useForm();
  const filterValues = watch(["priority", "status"]);
  const [params, setParams] = useFilter();
  const { data, isLoading } = useTickets(id, params);

  useEffect(() => {
    const values = {};

    for (let key in filterValues) {
      values[key] = filterValues[key]?.value ?? "";
    }

    setParams(values);
  }, [filterValues.status, filterValues.priority]);

  const handleSearch = () => {
    const filters = getValues();
    const values = {};

    for (let key in filters) {
      values[key] = filters[key]?.value ?? filters[key];
    }

    setParams(values);
  };

  useEffect(() => {
    if (user.isAdmin) {
      columns.splice(columns.length - 1, 0, {
        name: "priority",
        header: "Priority",
        defaultFlex: 1,
        render: ({ value }) => (
          <MetaBg type="priority" color={priorityColors[value]}>
            {value ? <span>{value}</span> : ""}
          </MetaBg>
        ),
      });
    }
  }, [user.isAdmin]);

  useEffect(() => {
    setParams({
      status: "active",
    });
  }, []);

  return (
    <Container maxWidth="false">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Heading>
          UserTickets <span>{status && <>/ {status}</>}</span>
        </Heading>
      </Box>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Search inputRef={register} onClick={handleSearch} />
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="status"
            control={control}
            options={user.isAdmin ? statusOptions : userStatusOptions}
            as={<Select placeholder="Status" defaultValue={null} />}
          />
        </Grid>
        <Grid item xs={3}>
          {user.isAdmin && (
            <Controller
              name="priority"
              control={control}
              options={priorityOptions}
              as={<Select placeholder="Priority" />}
            />
          )}
        </Grid>
      </Grid>

      <ReactDataGrid
        style={{ minHeight: 550, marginTop: 20 }}
        idProperty="uniqueId"
        columns={columns}
        dataSource={
          data?.sort(
            (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
          ) ?? []
        }
        idProperty="id"
        pagination
        rowHeight={60}
        loading={isLoading}
        showZebraRows={false}
      />
    </Container>
  );
}
