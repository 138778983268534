import React from "react";

import withSVG from "./withSVG";

const EditIcon = () => (
  <g fill="none">
    <path
      d="m13.474 3.908 2.118 2.117-2.118-2.117Zm1.362-1.865L9.109 7.77a2.118 2.118 0 0 0-.58 1.082L8 11.5l2.648-.53c.41-.082.786-.283 1.082-.58l5.727-5.726a1.854 1.854 0 0 0-2.621-2.621v0Z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 13.5v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-11a2 2 0 0 1 2-2h3"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default withSVG(EditIcon, {
  viewBox: "0 0 19 20",
  size: "16px",
});
