import { useAuth } from "./";
import { uploadURL } from "../config";

export default function useUpload() {
  const { user = {} } = useAuth();
  const token = user.accessToken;

  const uploadFile = async file => {
    const data = await fetch(`${uploadURL}?filename=${file.name}`, {
      method: "POST",
      body: file,
      headers: {
        "Content-type": file.type,
        Authorization: `Bearer ${token}`,
      },
    });

    const response = await data.json();

    return {
      success: 1,
      file: {
        url: response.upload.attachment.content_url,
      },
    };
  };

  return uploadFile;
}
