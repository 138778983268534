import React, { useRef, useState } from "react";
import { RichEditor, Button, TextField, Wrapper } from "components";
import styled from "styled-components";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useCreateTicket } from "hooks";

const ReplayWrapper = styled.div`
  background-color: #fff;
  margin: 24px 0;
`;

export default function CreateTicket() {
  const history = useHistory();
  const editorRef = useRef();
  const [subject, setSubject] = useState("");
  const { mutate, isLoading } = useCreateTicket();

  const handleCreateComment = content => {
    const body = {
      subject,
      content,
    };

    mutate(
      { data: body },
      {
        onSuccess: () => {
          editorRef.current.resetEditor();
          history.push("/tickets");
        },
      }
    );
  };

  return (
    <Wrapper mt="80px">
      <Container maxWidth="false">
        <TextField
          style={{ background: "#fff" }}
          placeholder="Enter your subject"
          fullWidth
          variant="outlined"
          margin="dense"
          value={subject}
          onChange={event => setSubject(event.target.value)}
        />
        <ReplayWrapper>
          <RichEditor
            placeholder="Write your ticket.."
            ref={editorRef}
            handleAddComment={handleCreateComment}
          />
        </ReplayWrapper>
        <Button
          mt={2}
          color="primary"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => editorRef.current.handleSave()}
        >
          Create Ticket
        </Button>
      </Container>
    </Wrapper>
  );
}
