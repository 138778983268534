import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useAuth from "./useAuth";

export default function useTicket(ticketId) {
  const axios = useAxios();
  const auth = useAuth();

  // Create a query with the key `projects`
  return useQuery(
    ["ticket", +ticketId],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get(
        `zd/${auth.user.id}/tickets/${ticketId}`
      );

      // Return the data from the Axios response
      return data?.data ?? {};
    },
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 60 * 1000,
    }
  );
}
