import React, { useEffect } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { PreLoader, Wrapper, Typography, Button } from "components";
import { useAuth } from "hooks";
import { useState } from "react";

export default function AuthCheckpost() {
  const [redirectTicket, setRedirectTicket] = useState();
  const location = useLocation();
  const history = useHistory();
  const { isAuth, login, isError } = useAuth();
  const params = new URLSearchParams(location.search);
  const code = params.get("code");
  const state = params.get("state");

  useEffect(() => {
    if (isAuth) {
      if (redirectTicket) {
        history.push(`/tickets/${redirectTicket}`);
      } else {
        history.push(`/`);
      }
    }
  }, [isAuth, history]);

  useEffect(() => {
    login({ code });
  }, [code]);

  useEffect(() => {
    setRedirectTicket(state);
  }, [state]);

  return (
    <>
      <PreLoader isLoading={!isError} />

      {isError && (
        <Wrapper
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <Typography variant="h3" color="error">
            Sorry, There is an error, please try again.
          </Typography>
          <Button
            mt={3}
            component={Link}
            color="primary"
            variant="contained"
            to="/login"
          >
            Go to login page
          </Button>
        </Wrapper>
      )}
    </>
  );
}
