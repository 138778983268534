import React from "react";
import { Dialog, Button, Radio, Typography } from "../components";
import { EditIcon } from "../icons";
import { Box } from "@material-ui/core";
import { useToggle, useUpdateTicket, useAuth } from "../hooks";
import { priorityOptions, statusOptions } from "../config";
import { useForm, Controller } from "react-hook-form";

export default function EditMeta({ ids = [], status, priority }) {
  const [open, setOpen] = useToggle();
  const { mutate, isLoading: isUpdating } = useUpdateTicket();
  const { control, getValues } = useForm();
  const { user } = useAuth();

  const onSubmit = () => {
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];

      mutate(
        { ticketId: id, data: { ...getValues() } },
        {
          onSuccess: () => {
            setOpen();
          },
        }
      );
    }
  };

  return (
    <form>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={setOpen}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
      <Dialog handleClose={setOpen} maxWidth="xs" open={open}>
        <Box p={4}>
          {user.isAdmin && (
            <Box mb={4}>
              <Typography mb={1} variant="h6">
                Change Priority
              </Typography>
              <Controller
                name="priority"
                control={control}
                as={
                  <Radio
                    initialValue={priority}
                    grid={6}
                    options={priorityOptions.slice(1)}
                  />
                }
              />
            </Box>
          )}
          <Box>
            <Typography mb={1} variant="h6">
              Change Status
            </Typography>
            <Controller
              name="status"
              control={control}
              initialValue={status}
              as={
                <Radio
                  grid={user.isAdmin ? 4 : 6}
                  options={statusOptions
                    .slice(1)
                    .filter(status =>
                      !user.isAdmin
                        ? ["closed", "solved"].includes(status.value)
                        : true
                    )}
                />
              }
            />
          </Box>

          <Box mt={4} textAlign="center">
            <Button
              disabled={isUpdating}
              loading={isUpdating}
              color="primary"
              variant="contained"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
    </form>
  );
}
