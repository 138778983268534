import React from "react";
import { useToggle } from "hooks";
import { Header, Footer, Drawer } from "components";
import { useAuth } from "hooks";
import styled from "styled-components";
import { mediaQuery } from "styles";

const PageWrapper = styled.div`
  display: flex;
`;

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

const Content = styled.main`
  margin-top: ${({ isAuth }) => (!isAuth ? "0" : "32px")};
  padding-top: 0;
  flex-grow: 1;
  width: 100%;

  ${mediaQuery.md`
    margin-top: ${({ isAuth }) => (!isAuth ? "0" : "32px")};
  `}
`;

const drawerOpenWidth = 200;
const drawerCloseWidth = 64;

const Layout = ({ children }) => {
  const { isAuth } = useAuth();
  const [open, handleToggleChange] = useToggle(true);

  return (
    <PageWrapper>
      {isAuth && (
        <Drawer
          handleToggleChange={handleToggleChange}
          drawerOpenWidth={drawerOpenWidth}
          drawerCloseWidth={drawerCloseWidth}
          open={open}
        />
      )}
      <Main>
        {isAuth && (
          <Header
            open={open}
            drawerOpenWidth={drawerOpenWidth}
            drawerCloseWidth={drawerCloseWidth}
          />
        )}
        <Content isAuth={isAuth}>{children}</Content>
        <Footer />
      </Main>
    </PageWrapper>
  );
};

export default Layout;
