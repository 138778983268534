import { useMutation, useQueryClient } from "react-query";
import { useFilter, useAuth, useAxios } from "./";

export default function useCreateTicket() {
  const axios = useAxios();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [params] = useFilter();

  return useMutation(({ data }) => axios.post(`zd/${user.id}/tickets`, data), {
    onSuccess: data => {
      const ticket = data?.data?.data ?? {};

      const ticketsQueryKey = !params
        ? ["tickets", user.id]
        : ["tickets", user.id, params];

      const isDataExist = queryClient.getQueryState(ticketsQueryKey);

      if (isDataExist) {
        queryClient.setQueryData(ticketsQueryKey, old => [...old, ticket]);
      }
    },
  });
}
