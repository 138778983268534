import React, { useImperativeHandle, forwardRef } from "react";
import edjsHTML from "editorjs-html";
import EditorJs from "react-editor-js";
import Table from "@editorjs/table";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import Image from "@editorjs/image";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import { useUpload } from "../../hooks";
import styled from "styled-components";
// import Embed from "@editorjs/embed";
// import LinkTool from "@editorjs/link";
// import CheckList from "@editorjs/checklist";

const Wrapper = styled.div`
  width: 100%;

  .ce-block__content,
  .ce-toolbar__content {
    max-width: calc(100% - 80px) !important;
  }
  .cdx-block {
    max-width: 100% !important;
  }
  .codex-editor__redactor {
    padding-bottom: 0 !important;
    min-height: 230px;
  }
`;

// Parse this block in editorjs-html
function tableParser({ data }) {
  const tableContent = data.content
    .map(
      item =>
        `<tr>
      ${item.map(td => `<td>${td}</td>`).join("")}
    </tr>`
    )
    .join("");

  return `<table> ${tableContent} </table>`;
}

function codeParser({ data }) {
  return `<pre><code>${data.code}</code></pre>`;
}

function ReactEditor({ placeholder = "", handleAddComment }, ref) {
  const instanceRef = React.useRef(null);
  const uploadFile = useUpload();

  const EDITOR_JS_TOOLS = {
    table: Table,
    list: List,
    code: Code,
    image: {
      class: Image,
      config: {
        /**
         * Custom uploader
         */
        uploader: {
          /**
           * Upload file to the server and return an uploaded image data
           * @param {File} file - file selected from the device or pasted by drag-n-drop
           * @return {Promise.<{success, file: {url}}>}
           */
          async uploadByFile(file) {
            return await uploadFile(file);
          },
        },
      },
    },
    header: Header,
    quote: Quote,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    // checklist: CheckList,
    // simpleImage: SimpleImage,
    // marker: Marker,
    // warning: Warning,
    // linkTool: LinkTool,
  };

  useImperativeHandle(ref, () => ({
    async handleSave() {
      const savedData = await instanceRef.current.save();

      const edjsParser = edjsHTML({ table: tableParser, code: codeParser });
      const html = edjsParser.parse(savedData);

      handleAddComment(html.join(""));
    },

    resetEditor() {
      instanceRef.current.clear();
    },
  }));

  return (
    <Wrapper>
      <EditorJs
        instanceRef={instance => (instanceRef.current = instance)}
        tools={EDITOR_JS_TOOLS}
        placeholder={placeholder}
        holder="custom"
      >
        <div
          id="custom"
          style={{
            width: "100%",
            border: "1px solid #D7D7D7",
            borderRadius: 4,
            minHeight: 0,
          }}
        ></div>
      </EditorJs>
    </Wrapper>
  );
}

export default forwardRef(ReactEditor);
