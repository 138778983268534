import React from "react";
import { Wrapper, Avatar, Typography, RawHTML } from "components";
import { formatDistance } from "date-fns";

export default function Comment({ comment, user }) {
  return (
    <Wrapper
      borderTop={1}
      mt={6}
      pt={3}
      borderColor="#D7D7D7"
      width="100%"
      flexDirection="column"
      display="flex"
    >
      <Wrapper display="flex" alignItems="center">
        <Avatar size={50} />
        <Wrapper ml={1}>
          <Typography>{!user ? "Agent" : user.name}</Typography>
          <Typography>
            {formatDistance(new Date(), new Date(comment.created_at), {
              includeSeconds: true,
            })}{" "}
            ago
          </Typography>
        </Wrapper>
      </Wrapper>
      <Wrapper
        mt={3}
        bgcolor={user ? "#fff" : "#F1F5FC"}
        p={!user ? 3 : 0}
        borderRadius={4}
        ml="58px"
      >
        <RawHTML html={comment.html_body} />
      </Wrapper>
    </Wrapper>
  );
}
