import { useMutation, useQueryClient } from "react-query";
import { useFilter, useAuth, useAxios } from "./";

export default function useUpdateTicket() {
  const axios = useAxios();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [params] = useFilter();

  return useMutation(
    ({ ticketId, data }) =>
      axios.patch(`zd/${user.id}/tickets/${ticketId}`, data),
    {
      onSuccess: data => {
        const ticket = data?.data?.data ?? {};

        queryClient.invalidateQueries(["comments", ticket.id]);
        queryClient.setQueryData(["ticket", ticket.id], old => ({
          ...old,
          ...ticket,
        }));

        const ticketsQueryKey = !params
          ? ["tickets", user.id]
          : ["tickets", user.id, params];

        const isDataExist = queryClient.getQueryState(ticketsQueryKey);

        if (isDataExist) {
          queryClient.setQueryData(ticketsQueryKey, old => {
            return old.map(OldTicket => {
              if (OldTicket.id === ticket.id) {
                return ticket;
              }

              return OldTicket;
            });
          });
        }
      },
    }
  );
}
