import React from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useAuth } from "../hooks";
import {
  Login,
  Tickets,
  AuthCheckpost,
  TicketDetails,
  Organizations,
  UserTickets,
  CreateTicket,
  UserTicketDetails,
} from "screens";
import { Layout } from "components";

const PrivateRoute = ({ component: Component, isAuth, ...props }) => {
  const location = useLocation();

  return (
    <Route
      {...props}
      render={innerProps =>
        isAuth ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { isAuth } = useAuth();

  return (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/auth-checkpost" component={AuthCheckpost} exact />
          <Route exact path="/">
            {isAuth ? <Redirect to="/tickets" /> : <Redirect to="/login" />}
          </Route>
          <PrivateRoute
            path="/tickets"
            component={Tickets}
            isAuth={isAuth}
            exact
          />
          <PrivateRoute
            path="/users/:id/tickets"
            component={UserTickets}
            isAuth={isAuth}
            exact
          />
          <PrivateRoute
            path="/users/tickets/:id"
            component={UserTicketDetails}
            isAuth={isAuth}
            exact
          />
          <PrivateRoute
            path="/tickets/create"
            component={CreateTicket}
            isAuth={isAuth}
            exact
          />
          <PrivateRoute
            path="/tickets/:id"
            component={TicketDetails}
            isAuth={isAuth}
            exact
          />
          <PrivateRoute
            path="/organizations"
            component={Organizations}
            isAuth={isAuth}
            exact
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default Routes;
