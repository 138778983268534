import React from "react";
import {
  Toolbar,
  IconButton,
  Grow,
  Popper,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { Wrapper, Typography } from "components";
import { ExitToApp } from "@material-ui/icons";
import { useAuth, useAnchor } from "hooks";
import AppBar from "@material-ui/core/AppBar";
import styled, { css } from "styled-components";
import { mediaQuery } from "styles";

const AppHeader = styled(({ drawerOpenWidth, drawerCloseWidth, ...rest }) => (
  <AppBar {...rest} />
))`
  background-color: #fff;
  box-shadow: none;
  color: ${({ theme }) => theme.palette.text.primary};

  ${props =>
    props.open
      ? css`
          transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
          margin-left: ${props => `${props.drawerOpenWidth}px`};
          width: ${props => `calc(100% - ${props.drawerOpenWidth}px)`};
        `
      : css`
          transition: all 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
          margin-left: ${props => `${props.drawerCloseWidth - 16}px`};
          width: ${`calc(100% - ${props.drawerCloseWidth - 16}px)`};
        `};

  ${mediaQuery.md`
    ${props =>
      !props.open &&
      css`
        width: ${`calc(100% - ${props.drawerCloseWidth}px)`};
      `};
      `}
`;

const Header = ({ open, drawerOpenWidth, drawerCloseWidth }) => {
  const { isAuth, logout } = useAuth();
  const [anchorEl, handleToggleChange] = useAnchor();

  const handleLogout = () => {
    logout();
  };

  return (
    <AppHeader
      open={open}
      drawerOpenWidth={isAuth ? drawerOpenWidth : 0}
      drawerCloseWidth={isAuth ? drawerCloseWidth : 0}
    >
      <Toolbar>
        {isAuth && (
          <Wrapper
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width="100%"
          >
            <IconButton
              aria-controls="logout-menu"
              aria-haspopup="true"
              onClick={handleToggleChange}
            >
              <ExitToApp />
            </IconButton>

            <Popper
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              transition
              disablePortal
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: "left bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleToggleChange}>
                      <MenuList
                        autoFocusItem={Boolean(anchorEl)}
                        id="menu-list-grow"
                      >
                        <MenuItem onClick={handleLogout}>
                          <Typography px={2}>Logout</Typography>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Wrapper>
        )}
      </Toolbar>
    </AppHeader>
  );
};

export default Header;
