import React from "react";

import withSVG from "./withSVG";

const OrganizationIcon = () => (
  <path
    d="M20.152 11.875v-.902c0-.802-.258-1.461-.773-1.977-.516-.516-1.175-.773-1.977-.773h-5.5V6.375c.516 0 .953-.179 1.31-.537.359-.358.538-.795.538-1.31V2.722c0-.516-.179-.953-.537-1.31a1.783 1.783 0 0 0-1.31-.538h-1.805c-.516 0-.953.179-1.31.537a1.783 1.783 0 0 0-.538 1.31v1.805c0 .516.179.953.537 1.31.358.359.795.538 1.31.538v1.848h-5.5c-.801 0-1.46.257-1.976.773s-.773 1.175-.773 1.977v.902c-.516 0-.953.179-1.31.537A1.783 1.783 0 0 0 0 13.722v1.805c0 .516.179.953.537 1.31.358.359.795.538 1.31.538h1.805c.516 0 .953-.179 1.31-.537.359-.358.538-.795.538-1.31v-1.805c0-.516-.179-.953-.537-1.31a1.783 1.783 0 0 0-1.31-.538v-.902c0-.63.314-.946.945-.946h5.5v1.848c-.516 0-.953.179-1.31.537a1.783 1.783 0 0 0-.538 1.31v1.805c0 .516.179.953.537 1.31.358.359.795.538 1.31.538h1.805c.516 0 .953-.179 1.31-.537.359-.358.538-.795.538-1.31v-1.805c0-.516-.179-.953-.537-1.31a1.783 1.783 0 0 0-1.31-.538v-1.848h5.5c.63 0 .945.315.945.946v.902c-.516 0-.953.179-1.31.537a1.783 1.783 0 0 0-.538 1.31v1.805c0 .516.179.953.537 1.31.358.359.795.538 1.31.538h1.805c.516 0 .953-.179 1.31-.537.359-.358.538-.795.538-1.31v-1.805c0-.516-.179-.953-.537-1.31a1.783 1.783 0 0 0-1.31-.538ZM10.098 2.723h1.804v1.804h-1.804V2.723ZM3.652 15.527H1.848v-1.804h1.804v1.804Zm8.25 0h-1.804v-1.804h1.804v1.804Zm6.446 0v-1.804h1.804v1.804h-1.804Z"
    fill="#fff"
  />
);

export default withSVG(OrganizationIcon, {
  viewBox: "0 0 22 18",
});
