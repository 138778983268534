import React from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import { spacing, typography, palette } from "@material-ui/system";

const TypographyExtended = styled(({ fontColor, ...rest }) => (
  <Typography {...rest} />
))`
  span {
    color: ${props => props.theme.palette.primary.main};
  }

  ${({ fontColor }) =>
    fontColor === "white" &&
    css`
      color: #fff;
    `}

  ${spacing}
  ${palette}
  ${typography}
`;

export default TypographyExtended;
