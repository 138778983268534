import React from "react";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

const RawHTMLContainer = styled.div`
  height: 100%;
  line-height: 30px;
  font-size: 18px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
  }

  p {
    margin-bottom: 8px;
  }

  * {
    margin: 0;
    padding: 0;
  }

  table {
    border-collapse: collapse;
    margin: 32px 0;
    font-size: 0.9em;
    width: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 15%);

    th,
    td {
      padding: 12px 15px;
    }

    tbody tr {
      border-bottom: 1px solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
      background-color: #f3f3f3;
    }

    tbody tr:last-of-type {
      border-bottom: 2px solid #009879;
    }
  }

  pre {
    box-shadow: 0 0 5px rgb(0 0 0 / 15%);
    padding: 30px;
    background: #242d3f;
    margin: 40px 0;
    border-radius: 10px;
    color: #fff;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
  }

  blockquote:before {
    color: #ccc;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  ${spacing};
`;

const RawHTML = ({ html, ...props }) => {
  return (
    <RawHTMLContainer dangerouslySetInnerHTML={{ __html: html }} {...props} />
  );
};

export default RawHTML;
