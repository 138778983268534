import React from "react";
import { Typography, Wrapper, Avatar } from "components";
import styled from "styled-components";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/theme/default-light.css";
import { ArrowForward } from "@material-ui/icons";
import { IconButton, Container } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useOrganization } from "hooks";

const Heading = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;

  span {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
  }
`;

const columns = [
  {
    name: "",
    header: "User Info",
    defaultFlex: 1,
    render: ({ data }) => (
      <Wrapper display="flex">
        <Avatar src={data?.photo?.content_url} size={30} />
        <Typography ml={1}>{data.name}</Typography>
      </Wrapper>
    ),
  },
  { name: "email", header: "Email", defaultFlex: 1 },
  {
    defaultWidth: 80,
    render: ({ data }) => (
      <IconButton component={Link} to={`/users/${data.id}/tickets`}>
        <ArrowForward />
      </IconButton>
    ),
  },
];

export default function Organization() {
  const { data, isLoading } = useOrganization();

  return (
    <Container maxWidth="false">
      <Heading>Organization</Heading>

      <ReactDataGrid
        style={{ minHeight: 550, marginTop: 50 }}
        columns={columns}
        dataSource={data || []}
        pagination
        rowHeight={60}
        loading={isLoading}
        showZebraRows={false}
      />
    </Container>
  );
}
