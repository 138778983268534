import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useAuth from "./useAuth";

export default function useOrganization() {
  const axios = useAxios();
  const { user } = useAuth();

  // Create a query with the key `projects`
  return useQuery(
    "organization",
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get(
        `/zd/${user.id}/organizations/${user.organizationId}/users`
      );

      // Return the data from the Axios response
      return data?.data ?? [];
    },
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 60 * 1000,
      enabled: !!user.organizationId,
    }
  );
}
