import React, { useEffect } from "react";
import querystring from "querystring";
import * as config from "../config";
import { Wrapper, Typography, Image, Button } from "components";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";
import { useAuth } from "hooks";
import logo from "../assets/images/logo.png";
import videoThumb from "../assets/images/video-screenshot.png";

const LoginPage = () => {
  const { isAuth } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const path = location.state?.from?.pathname ?? "";

  useEffect(() => {
    if (isAuth) {
      history.push("/");
    }
  }, [isAuth, history]);

  const handleLogin = () => {
    const params = {
      response_type: "code",
      redirect_uri: config.ZDOAuthRedirectUrl,
      client_id: config.ZDOAuthClientId,
      scope: "read write",
      state: path.split("/").pop(),
    };

    const url = `https://${
      config.ZDSubdomain
    }.zendesk.com/oauth/authorizations/new?${querystring.stringify(params)}`;

    window.location.href = url;
  };

  return (
    <Container>
      <Wrapper
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Wrapper
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          mb={5}
          width="60%"
          mx="auto"
          textAlign="center"
        >
          <Image src={logo} alt="Orocube.com" mb={3} />
          <Typography
            variant="h2"
            align="center"
            style={{ color: "#646464", fontSize: "40px" }}
          >
            Welcome to ORO Zendesk Portal
          </Typography>

          <Button
            minWidth="220"
            variant="contained"
            color="primary"
            justify="flex-start"
            mt={3}
            onClick={handleLogin}
          >
            Sign in
          </Button>

          <Typography mt={6}>
            You need to have a zendesk account to access your existing support
            ticket & create new ticket. To sign in Please see the bottom video
          </Typography>
        </Wrapper>
        <Wrapper display="flex" justifyContent="center">
          <video
            style={{
              margin: "0 auto",
              width: "60%",
              filter: "drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12))",
              cursor: "pointer",
            }}
            controls
            poster={videoThumb}
          >
            <source
              src="https://www.dropbox.com/s/2695tqfthwflhl6/Video_tutorial_zd.mp4?raw=1"
              type="video/mp4"
            />
          </video>
        </Wrapper>
      </Wrapper>
    </Container>
  );
};

export default LoginPage;
