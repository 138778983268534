import { useQuery } from "react-query";
import useAxios from "./useAxios";
import useAuth from "./useAuth";

export default function useComments(ticketId) {
  const axios = useAxios();
  const { user } = useAuth();

  // Create a query with the key `projects`
  return useQuery(
    ["comments", +ticketId],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get(
        `zd/${user.id}/tickets/${ticketId}/comments`
      );

      // Return the data from the Axios response
      return data?.data ?? [];
    },
    {
      staleTime: 60 * 1000,
    }
  );
}
