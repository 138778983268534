import React from "react";

import withSVG from "./withSVG";

const TicketsIcon = () => (
  <path
    d="M19.6 1.7v1.8a.6.6 0 0 1-.6.6 2.4 2.4 0 0 0 0 4.8.6.6 0 0 1 .6.6v1.8a1.2 1.2 0 0 1-1.2 1.2H1.6a1.2 1.2 0 0 1-1.2-1.2V9.5a.6.6 0 0 1 .6-.6 2.4 2.4 0 0 0 0-4.8.6.6 0 0 1-.6-.6V1.7A1.2 1.2 0 0 1 1.6.5h16.8a1.2 1.2 0 0 1 1.2 1.2Z"
    fill="#fff"
  />
);

export default withSVG(TicketsIcon, {
  viewBox: "0 0 20 13",
});
