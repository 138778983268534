import React from "react";
import Select from "react-select";

const customStyles = {
  option: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: 24,
  }),
};

const SelectExtended = ({
  name,
  value,
  options = [],
  onChange,
  isMulti,
  defaultValue,
  placeholder,
}) => {
  return (
    <Select
      name={name}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      isMulti={isMulti}
      styles={customStyles}
    />
  );
};

export default SelectExtended;
