import { useQuery } from "react-query";
import useAxios from "./useAxios";

export default function useTickets(userId, params) {
  const axios = useAxios();

  return useQuery(
    params ? ["tickets", userId, params] : ["tickets", userId],
    async () => {
      // Fetch data from our API using Axios. We'll talk about the typing below
      const { data } = await axios.get(`zd/${userId}/tickets`, {
        params,
      });

      // Return the data from the Axios response
      return data?.data ?? [];
    },
    {
      cacheTime: 1000 * 60 * 10,
      staleTime: 60 * 1000,
    }
  );
}
