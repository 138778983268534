import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import styled from "styled-components";
import { Typography, Image } from "components";
import logo from "../assets/images/logo.png";

const FooterWrapper = styled.footer`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.paper};
  border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
  padding: ${props => props.theme.space[1] + "px"};
  margin-top: 60px;
  padding-top: 40px;
`;

const FooterTypography = styled(Typography)`
  a {
    color: ${({ theme }) => theme.palette.primary.main};

    :hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.palette.text.primary};

  :hover {
    color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const LinkItem = styled.li`
  padding: 5px 10px;
`;

const linksGeneral = [
  {
    label: "Privacy Policy",
    link: "https://pos.orocube.com/privacy-policy/",
  },
  {
    label: "Support",
    link: "https://pos.orocube.com/support/",
  },
  {
    label: "Payments",
    link: "https://pos.orocube.com/payments/",
  },
];

const linksProducts = [
  {
    label: "ORO POS",
    link: "https://pos.orocube.com/",
  },
  {
    label: "Floreant POS",
    link: "https://floreant.org/",
  },
  {
    label: "Menugreat",
    link: "https://menugreat.com/",
  },
];

const QuickLinksHeading = styled.p`
  margin-left: 8px;
  font-size: 20px;
  margin-bottom: 16px;
`;

const Footer = () => (
  <FooterWrapper>
    <Container>
      <Grid container alignItems="center" spacing={4}>
        <Grid item sm={12} md={4}>
          <Image src={logo} alt="Orocube.com" mb={2} />
          <Box>
            <Typography>
              1509 Johnson Ferry Rd T6, Marietta GA 30062, USA
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={12} md={4}>
          <QuickLinksHeading>Quick Links</QuickLinksHeading>
          <Grid container>
            <Grid item sm={2} md={6}>
              <ul>
                {linksGeneral.map(link => (
                  <LinkItem>
                    <Link href={link.link}>{link.label}</Link>
                  </LinkItem>
                ))}
              </ul>
            </Grid>
            <Grid item sm={2} md={6}>
              <ul>
                {linksProducts.map(link => (
                  <LinkItem>
                    <Link href={link.link}>{link.label}</Link>
                  </LinkItem>
                ))}
              </ul>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={4}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-end"
            flexDirection="column"
          >
            <FooterTypography>
              Toll Free : <a href="tel:+18008446603">+1 (800) 844-6603</a>
            </FooterTypography>
            <FooterTypography>
              E-mail:{" "}
              <a href="mailto:support@orocube.net">support@orocube.net</a>
            </FooterTypography>
          </Box>
        </Grid>
      </Grid>
      <Box mt={4}>
        <Typography variant="subtitle1" textAlign="center">
          © {new Date().getFullYear()} - zd.orocube.com
        </Typography>
      </Box>
    </Container>
  </FooterWrapper>
);

export default Footer;
