import React from "react";
import { useParams } from "react-router-dom";
import {
  Wrapper,
  Typography,
  PreLoader,
  EditMeta,
  Comment,
  Reply,
  Avatar,
} from "components";
import { format, formatDistance } from "date-fns";
import { Container } from "@material-ui/core";
import { useTicket, useOrganization, useComments, useAuth } from "hooks";
import styled from "styled-components";
import { statusColors, priorityColors } from "../config";

const MetaBg = styled.p`
  span {
    background: ${({ type, color }) =>
      type === "priority" ? "transparent" : color};
    padding: 4px 16px;
    border-radius: 20px;
    color: ${({ type, color }) => (type === "priority" ? color : "#fff")};
    text-transform: capitalize;
  }
`;

export default function TicketDetails() {
  const { id } = useParams();
  const { data: ticket = {}, isLoading } = useTicket(id);
  const { data: organizationUsers } = useOrganization();
  const { data: comments = [], isLoading: isCommentsLoading } = useComments(id);
  const { user } = useAuth();

  return (
    <PreLoader isLoading={isLoading || isCommentsLoading}>
      <Wrapper mt={4} borderBottom="1px solid #cdcdcd" mb={3}>
        <Wrapper mb={4} bgcolor="#EDF3FB">
          <Container maxWidth="false">
            <Wrapper
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Wrapper display="flex" alignItems="center">
                <MetaBg color={statusColors[ticket.status]}>
                  {ticket.status ? <span>{ticket.status}</span> : ""}
                </MetaBg>
                <Typography
                  variant="body2"
                  mr={2}
                  ml={2}
                  style={{ textDecoration: "underline" }}
                >
                  Ticket No: #{ticket.id}
                </Typography>

                {user.isAdmin && (
                  <MetaBg
                    type="priority"
                    color={priorityColors[ticket.priority]}
                  >
                    {ticket.status ? <span>{ticket.priority}</span> : ""}
                  </MetaBg>
                )}
              </Wrapper>
              <Wrapper>
                <EditMeta
                  status={ticket.status}
                  priority={ticket.priority}
                  ids={[ticket.id]}
                />
              </Wrapper>
            </Wrapper>
          </Container>
        </Wrapper>
        <Wrapper>
          <Container maxWidth="false">
            <Wrapper display="flex">
              <Avatar size={50} />
              <Wrapper ml={2}>
                <Typography variant="h3" mb={1}>
                  {ticket.subject}
                </Typography>
                {ticket.created_at && ticket.updated_at && (
                  <Typography variant="subtitle1" mb={2}>
                    {formatDistance(new Date(), new Date(ticket.updated_at), {
                      includeSeconds: true,
                    })}{" "}
                    ago updated
                    <span style={{ margin: "0 10px", display: "inline-block" }}>
                      |
                    </span>
                    Created At:{" "}
                    {format(new Date(ticket.created_at), "yyyy/MM/dd")}
                  </Typography>
                )}
              </Wrapper>
            </Wrapper>
          </Container>
        </Wrapper>
      </Wrapper>
      <Container maxWidth="false">
        <Reply ticketId={id} userId={user.id} />
        <Wrapper>
          {comments
            .filter(item => item.public)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map(comment => (
              <Comment
                comment={comment}
                key={comment.id}
                user={
                  comment.author_id === user.id
                    ? user
                    : organizationUsers?.data?.find(
                        user => user.id === comment.author_id
                      ) || null
                }
              />
            ))}
        </Wrapper>
      </Container>
    </PreLoader>
  );
}
