import React from "react";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
} from "@material-ui/core";

export default function RadioButtonsGroup({
  options = [],
  name,
  label,
  onChange,
  grid = 12,
  initialValue,
}) {
  const [value, setValue] = React.useState(initialValue);

  const handleChange = event => {
    setValue(event.target.value);
    onChange(event);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        value={value}
        aria-label={label}
        name={name}
        onChange={handleChange}
      >
        <Grid container>
          {options.map((option, i) => (
            <Grid item xs={grid} key={i}>
              <FormControlLabel
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
