import { TicketsIcon, OrganizationIcon } from "icons";

export const apiEndpoint = process.env.REACT_APP_API;
export const maxImageSize = 10 * 1024 * 1024;
export const ZDSubdomain = process.env.REACT_APP_ZD_SUBDOMAIN;
export const ZDOAuthClientId = process.env.REACT_APP_ZD_OAUTH_CLIENT_ID;
export const ZDOAuthRedirectUrl = process.env.REACT_APP_ZD_OAUTH_REDIRECT_URL;
export const uploadURL = "https://orocube.zendesk.com/api/v2/uploads.json";

export const menu = [
  {
    link: "/tickets",
    label: "Tickets",
    icon: <TicketsIcon size={20} />,
  },
  {
    link: "/organizations",
    label: "Organization",
    icon: <OrganizationIcon size={20} />,
  },
];

export const priorityOptions = [
  {
    value: "",
    label: "All",
  },
  {
    value: "urgent",
    label: "Urgent",
  },
  {
    value: "high",
    label: "High",
  },
  {
    value: "normal",
    label: "Normal",
  },
  {
    value: "low",
    label: "Low",
  },
];

export const statusOptions = [
  {
    value: "",
    label: "All",
  },
  {
    value: "new",
    label: "New",
  },
  {
    value: "open",
    label: "Open",
  },
  {
    value: "solved",
    label: "Solved",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

export const userStatusOptions = [
  {
    value: "",
    label: "All",
  },
  {
    value: "active",
    label: "Active",
  },
  {
    value: "solved",
    label: "Solved",
  },
  {
    value: "closed",
    label: "Closed",
  },
];

export const statusInitials = {
  new: "OP",
  open: "OP",
  closed: "CL",
  solved: "SL",
};

export const statusColors = {
  new: "#00b16a",
  open: "#00b16a",
  closed: "#7c8b86",
  solved: "#6bd4d7",
};

export const priorityColors = {
  normal: "#f39c12",
  low: "#00b16a",
  urgent: "#8e44ad",
  high: "#f22613",
};
