import { createContext, useState, useContext } from "react";

export const FilterContext = createContext();

export function FilterProvider(props) {
  const [filter, setFilter] = useState();

  return <FilterContext.Provider value={[filter, setFilter]} {...props} />;
}

export default function useFilter() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error(`useFilter must be used within a FilterProvider`);
  }
  return context;
}
